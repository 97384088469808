<script lang="ts" setup> 

    import { useIntersectionObserver } from '@vueuse/core'

    useHead({
        script: [
            { 
                hid: 'vimeo', 
                src: 'https://player.vimeo.com/api/player.js',
                defer: true 
            }
        ]
    })

    const props = defineProps({ 
        blok: Object 
    })

    const richtext = (value:any) => renderRichText(value)

    /* Target */
    const target = ref(null)

    /* Visible */
    const visible = ref(false)

    useIntersectionObserver( target, ([{ isIntersecting }], observerElement) => {
        visible.value = isIntersecting
    })

</script>

<template>
    <div ref="target" v-editable="blok" class="case-studies-carousel | grid grid-col-1" :class="[blok?.theme || 'dark']">
        <div class="flex flex-col gap-6 lg:gap-8">
        
            <div class="flex flex-col gap-4 justify-center items-center text-center max-w-[900px] mx-auto">
                <div class="flex flex-col gap-2 justify-center items-center text-center">
                    <component
                        v-if="blok && blok?.heading" 
                        :is="`headings-${blok?.heading_tag || 'h3'}`" 
                        :value="blok?.heading"
                        :classes="blok?.heading_style || 'h2'" 
                    />
                    <component
                        v-if="blok && blok?.subheading" 
                        :is="`headings-${blok?.subheading_tag || 'h4'}`" 
                        :value="blok?.subheading"
                        :classes="blok?.subheading_style || 'h3'"
                    />
                </div>
                <div v-if="blok?.richtext && richtext(blok?.richtext)" v-html="richtext(blok?.richtext)" class="flex flex-col gap-2 | font-hass-text text-p_mobile md:text-p_tablet lg:text-p_desktop font-normal text-white-50/75 dark:text-nevada-500"/>
            </div>

            <div v-if="blok?.vimeo">
                <div class="relative pt-[56.25%] overflow-hidden rounded-1 | [&>iframe]:absolute [&>iframe]:top-0 [&>iframe]:left-0 [&>iframe]:w-full [&>iframe]:h-full [&>iframe]:border-0">
                    <iframe v-if="visible && blok?.vimeo" :id="`vimeo-${blok?.vimeo}`" :src="`https://player.vimeo.com/video/${blok?.vimeo}?autoplay=1&loop=1&controls=true&muted=1`" width="640" height="360" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                 </div>
            </div>

            <div v-if="blok?.buttons && blok?.buttons.length > 0" class="flex flex-wrap gap-2 justify-center">
                <StoryblokComponent v-for="button in blok.buttons" :key="button._uid" :blok="button" />
            </div>

        </div>
    </div>
</template>


